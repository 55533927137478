import { Dropdown } from "tailwindcss-stimulus-components"

export default class extends Dropdown {
  static targets = ["menu"];
  declare menuTarget: HTMLElement;
  declare element: HTMLElement;
  private observer: MutationObserver;

  connect(): void {
    super.connect();

    this.observer = new MutationObserver(() => {
      const isOpen = this.element.dataset.dropdownWithTooltipOpenValue === 'true';
      const controllers = this.element.dataset.controller?.split(" ") || [];

      this.element.dataset.controller = isOpen
        ? controllers.filter(c => c !== "stimulus-tooltip").join(" ")
        : [...new Set([...controllers, "stimulus-tooltip"])].join(" ")
    });

    this.observer.observe(this.element, {
      attributes: true,
      attributeFilter: ['data-dropdown-with-tooltip-open-value']
    });
  }

  disconnect(): void {
    super.disconnect();
    this.observer.disconnect();
  }
}
