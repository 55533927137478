import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static values = {
    delay: Number,
  };

  static targets = ["input"];

  declare timeout: number | null;
  declare delayValue: number;
  declare inputTarget: HTMLInputElement | HTMLTextAreaElement;
  declare initialValue: string;

  initialize(): void {
    this.timeout = null;
  }

  connect(): void {
    this.initialValue = this.inputTarget.value;
  }

  save(): void {
    if (this.timeout) {
      clearTimeout(this.timeout);
    }

    // Only save if the value has actually changed
    if (this.inputTarget.value !== this.initialValue) {
      this.timeout = window.setTimeout(() => {
        this.dispatch("saving");
        const form = this.element as HTMLFormElement;
        form.requestSubmit();
        // Update the initial value after saving
        this.initialValue = this.inputTarget.value;
      }, this.delayValue);
    }
  }
}
