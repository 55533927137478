import { Controller } from "@hotwired/stimulus";
import { formatCurrencyValue } from "../helpers/currency_formatter";

// Connects to data-controller="input-currency-formatter"
export default class extends Controller {
  static values = {
    skipEnterKeyAsTab: Boolean,
    strictValidation: {
      type: Boolean,
      default: false
    }
  };

  declare skipEnterKeyAsTabValue: boolean;
  declare strictValidationValue: boolean;
  private lastValidValue: string = "";

  connect() {
    this.formatInput();
  }

  formatInput() {
    const inputValue = (this.element as HTMLInputElement).value;
    try {
      const formattedValue = formatCurrencyValue(inputValue, {
        throwOnInvalid: this.strictValidationValue
      });

      if (formattedValue !== inputValue) {
        (this.element as HTMLInputElement).value = formattedValue;
      }
      this.lastValidValue = formattedValue;
    } catch {
      // If strict validation is on and value is invalid, restore last valid value
      if (this.strictValidationValue) {
        (this.element as HTMLInputElement).value = this.lastValidValue;
      }
    }
  }

  highlight() {
    (this.element as HTMLInputElement).select();
  }

  handleEnterKeyAsTab(event) {
    if (this.skipEnterKeyAsTabValue) return;
    
    event.preventDefault();
    this.focusOnNextInput(event.currentTarget);
  }

  focusOnNextInput(currentTarget) {
    if (this.skipEnterKeyAsTabValue) return;

    const inputs = document.querySelectorAll("input, textarea");
    const currentIndex = Array.from(inputs).indexOf(currentTarget);
    if (inputs[currentIndex]) {
      (inputs[currentIndex + 1] as HTMLElement).focus();
    }
  }
}
