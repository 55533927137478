import StimulusFlatpickr from "stimulus-flatpickr";
import { Instance } from "flatpickr/dist/types/instance";
import { TomInput } from "tom-select/dist/cjs/types";

type DispatchOptions = Partial<{
  target: Element | Window | Document;
  detail: object;
  prefix: string;
  bubbles: boolean;
  cancelable: boolean;
}>;

export default class extends StimulusFlatpickr {
  declare fp: Instance;
  declare actualsEndDateTarget: TomInput;
  declare dispatch: (eventName: string, options?: DispatchOptions) => CustomEvent;

  static targets = ["actualsEndDate"]

  // Hook fired when calendar is closed
  close(selectedDates, dateStr, _instance) {   
    if (!Object.values(this.actualsEndDateTarget.tomselect.options).map(x => x.value).includes(dateStr)) {
      this.actualsEndDateTarget.tomselect.addOption({ value: dateStr, text: this.fp.formatDate(selectedDates[0], "n/j/Y") }, true);
    }

    this.actualsEndDateTarget.tomselect.setValue(dateStr);
    this.dispatch("submit");
  }

  // Action invoked when tomselect option is selected
  input({ target: { value } }) {
    if (value == "custom") {
      this.fp.open();
    } else {
      this.dispatch("submit");
    }
  }
}
