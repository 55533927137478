import { Controller } from "@hotwired/stimulus";
import TomSelect from "tom-select";
import { TomInput } from "tom-select/dist/cjs/types";

export default class extends Controller<TomInput> {
  declare select: TomSelect;

  static values = {
    classes: Array,
    itemClasses: { type: Array, default: [] },
    optgroupHeaderClasses: { type: Array, default: ["optgroup-header", "font-semibold", "border-t"] },
    wrapperClasses: { type: Array, default: [] },
    hidePlaceholder: { type: Boolean, default: false },
    variant: String,
    maxOptions: { type: Number, default: 100 },
    persist: { type: Boolean, default: true },
    plugins: { type: Array, default: ["remove_button"] },
  };

  declare classesValue: string[];
  declare hasClassesValue: boolean;
  declare itemClassesValue: string[];
  declare hasItemClassesValue: boolean;
  declare wrapperClassesValue: string[];
  declare hasWrapperClassesValue: boolean;
  declare hidePlaceholderValue: boolean;
  declare variantValue: string;
  declare maxOptionsValue: number;
  declare persistValue: boolean;
  declare pluginsValue: string[];
  declare optgroupHeaderClassesValue: string[];

  connect() {
    const options = {
      plugins: this.pluginsValue,
      maxOptions: this.maxOptionsValue,
      itemClass: `item ${this.itemClassesValue.join(" ")}`,
      persist: this.persistValue,
      render: {
        optgroup_header: (data, escape) => {
          return `
            <div class="${this.optgroupHeaderClassesValue.join(" ")}">
              ${escape(data.label)}
            </div>
          `;
        },
      },
    };

    // If we've set a placeholder from the select component we want to hide when options are selected
    // Do so with the hidePlaceholder option here
    if (this.hidePlaceholderValue) {
      options["hidePlaceholder"] = true;
    }

    if (this.variantValue == "withRadioOptions") {
      options["render"]["option"] = (data, escape) => {
        const checked = this.select.items.includes(data.value) ? "checked" : "";
        return `
          <div class="my-1 gap-x-2 flex items-center">
            <input type="radio" autocomplete="off" ${checked} />
            ${escape(data.text)}
          </div>
        `;
      };

      options["onChange"] = () => {
        Object.keys(this.select.options).forEach((key) => {
          const hasTomSelectRendered = this.select.options[key].$div !== undefined;
          if (!hasTomSelectRendered) {
            return;
          }

          const radio = this.select.options[key].$div.children[0] as HTMLInputElement;

          if (this.select.items.includes(key)) {
            radio.checked = true;
          } else {
            radio.checked = false;
          }
        });
      };

      options["onDelete"] = () => {
        this.dispatch("onDelete");
        return true;
      };
    }

    this.select = new TomSelect(this.element, options);

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        if (className != "") {
          this.select.control.classList.add(className);
        }
      });
    }

    if (this.hasWrapperClassesValue) {
      this.wrapperClassesValue.forEach((className) => {
        if (className != "") {
          this.select.wrapper.classList.add(className);
        }
      });
    }
  }

  resetSelect() {
    this.select.clear();
  }

  unfocusSelect() {
    this.select.blur();
  }

  disconnect() {
    this.select.destroy();
  }
}
