import { Controller } from "@hotwired/stimulus";
import { post } from "@rails/request.js";
import {
  FinicityConnect,
  ConnectEventHandlers,
  ConnectOptions,
  ConnectDoneEvent,
  ConnectCancelEvent,
  ConnectErrorEvent,
} from "@finicity/connect-web-sdk";

export default class extends Controller<HTMLFormElement> {
  static targets = ["connectButton", "connectButtonIcon", "loadingIcon"];
  static values = { connectUrl: String, modalOpen: { type: Boolean, default: false } };

  declare connectButtonTarget: HTMLButtonElement;
  declare connectButtonIconTarget: SVGElement;
  declare loadingIconTarget: SVGElement;
  declare connectUrlValue: string;
  declare modalOpenValue: boolean;

  connect() {
    document.addEventListener("turbo:before-stream-render", this.handleTurboBeforeStreamRender.bind(this));
  }

  disconnect() {
    FinicityConnect.destroy();
    document.removeEventListener("turbo:before-stream-render", this.handleTurboBeforeStreamRender.bind(this));
  }

  // Prevent Turbo from refreshing the page when the connect modal is open
  handleTurboBeforeStreamRender(event: Event) {
    if (this.modalOpenValue) {
      event.preventDefault();
    }
  }

  async makeConnection() {
    this.showLoader();

    const jsonResponse = await this.generateConnectUrl();
    const connectUrl = jsonResponse.link;

    if (jsonResponse.error) {
      console.error(`Error generating Connect URL. ${jsonResponse.error}`);
      this.hideLoader();
      return;
    } else if (!connectUrl) {
      console.error(`Error generating Connect URL. No link returned.`);
      this.hideLoader();
      return;
    }

    const connectEventHandlers: ConnectEventHandlers = {
      onDone: (_event: ConnectDoneEvent) => {
        this.element.requestSubmit();
        this.modalOpenValue = false;
      },
      onCancel: (_event: ConnectCancelEvent) => {
        this.modalOpenValue = false;
      },
      onError: (event: ConnectErrorEvent) => {
        this.modalOpenValue = false;
        console.error(event);
      },
      onLoad: () => {
        this.modalOpenValue = true;
      },
    };

    const connectOptions: ConnectOptions = {
      overlay: "rgba(199,201,199, 0.5)",
    };

    FinicityConnect.launch(connectUrl, connectEventHandlers, connectOptions);
  }

  async generateConnectUrl(): Promise<{ link?: string; error?: string }> {
    const response = await post(this.connectUrlValue, { body: {}, responseKind: "json" });

    return response.json;
  }

  modalOpenValueChanged(value: boolean, _previous: boolean) {
    if (value) {
      this.showLoader();
    } else {
      this.hideLoader();
    }
  }

  showLoader() {
    this.connectButtonTarget.disabled = true;
    this.connectButtonIconTarget.classList.add("hidden");
    this.loadingIconTarget.classList.remove("hidden");
  }

  hideLoader() {
    this.connectButtonTarget.disabled = false;
    this.connectButtonIconTarget.classList.remove("hidden");
    this.loadingIconTarget.classList.add("hidden");
  }
}
