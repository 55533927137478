import { Controller } from "@hotwired/stimulus"
import { formatCurrencyValue } from "../helpers/currency_formatter"

// Connects to data-controller="copy-amount"
export default class extends Controller {
  static targets = ["sourceAmountField", "targetAmountField"]

  declare sourceAmountFieldTarget: HTMLInputElement;
  declare targetAmountFieldTarget: HTMLInputElement;
  declare hasSourceAmountFieldTarget: boolean;
  declare hasTargetAmountFieldTarget: boolean;

  connect() {
    this.updateAmount();
  }

  updateAmount() {
    if (this.hasSourceAmountFieldTarget && this.hasTargetAmountFieldTarget) {
      try {
        const formattedValue = formatCurrencyValue(this.sourceAmountFieldTarget.value, { throwOnInvalid: true });
        this.targetAmountFieldTarget.value = formattedValue;
      } catch {
        this.targetAmountFieldTarget.value = "";
      }
    }
  }
}
