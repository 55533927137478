import { Controller } from "@hotwired/stimulus";
import {
  ColDef,
  ColGroupDef,
  createGrid,
  GridApi,
  GridOptions,
  InitialGroupOrderComparatorParams,
} from "ag-grid-enterprise";
import { dataTypeDefinitions } from "../helpers/reports/formatters";
import { IBudgetAnalysisVariance, IBudgetAnalysisRowData } from "../types/budget_analysis";
import { accountCodeStyledCell } from "../helpers/reports/renderers";
import { ActualCellRenderer} from "../helpers/budget_analysis/renderers";

export default class extends Controller {
  static values = {
    rowData: Array,
    columnDefs: Array,
    completedPeriodColumns: Array
  };

  static targets = ["grid", "option"];

  // Stimulus Values
  declare columnDefsValue: (ColGroupDef & { children: ColDef<IBudgetAnalysisVariance>[] })[];
  declare completedPeriodColumnsValue: string[];
  declare rowDataValue: IBudgetAnalysisRowData[];
  declare hasRowDataValue: boolean;

  // Stimulus Targets
  declare gridTarget: HTMLDivElement;
  declare optionTargets: HTMLInputElement[];
  
  // Local Variables
  declare gridApi: GridApi;
  declare gridOptions: GridOptions;

  connect() {
    if (this.hasRowDataValue) {
      this.gridOptions = {
        columnDefs: [
          { field: "budget_group", rowGroup: true, hide: true },
          {
            field: "name",
            pinned: "left",
            cellClass: "!whitespace-pre",
            headerName: "",
            suppressSpanHeaderHeight: true,
            resizable: true,
            width: 380,
            cellRenderer: params => {
              const eDiv = document.createElement('div');

              if (params.data.type === 'account') {
                if (this.isOptionChecked("show_account_categories")) {
                  eDiv.classList.add(`pl-${params.data.nested_level * 8}`);
                } else {
                  eDiv.classList.add('pl-8');
                }

                eDiv.innerHTML = params.value;
            
                return eDiv;
              } else if (params.data.type === 'account_code') {
                if (this.isOptionChecked("show_account_categories")) {
                  eDiv.classList.add(`pl-${params.data.nested_level * 8}`);
                } else {
                  eDiv.classList.add('pl-16');
                }

                eDiv.innerHTML = accountCodeStyledCell(params.value, "#E1EFFE", "#6A6A6A").replace(/\s+/g, ' ').trim();
            
                return eDiv;
              } else {
                return params.value;
              }
            }
          },
          ...this.columnDefsValue,
        ],
        components: { actualCellRenderer: ActualCellRenderer },
        dataTypeDefinitions: {
          ...dataTypeDefinitions
        },
        autoSizeStrategy: {
          type: 'fitCellContents'
        },  
        defaultColDef: {
          resizable: false,
          sortable: false,
          width: 132,
          minWidth: 132,
          suppressHeaderMenuButton: true,
          suppressMovable: true,
          headerClass: "bg-gray-100 !text-gray-800 font-medium",
          cellClass: "text-right"
        },
        defaultColGroupDef: {
          headerClass: "bg-sumit-primary-cyan-700",
        },
        initialGroupOrderComparator: (params: InitialGroupOrderComparatorParams<IBudgetAnalysisRowData>) => {
          const order = ['Revenues', '', 'Expenses', null];
          
          const nodeAValue = params.nodeA.key || params.nodeA?.data?.budget_group;
          const nodeBValue = params.nodeB.key || params.nodeB?.data?.budget_group;

          return order.indexOf(nodeAValue) - order.indexOf(nodeBValue);
        },
        groupAllowUnbalanced: true,
        groupDefaultExpanded: 1,
        groupDisplayType: 'groupRows',
        groupRowRendererParams: {
          suppressCount: true,
        },
        rowData: this.rowDataValue,
        onGridReady: () => {
          this.toggleAccountCategories();
          this.toggleCompletedPeriods();
          this.toggleVarianceAmount();
          this.toggleVariancePercentage();
          this.toggleRemainingBudget();
        }
      };
      this.gridApi = createGrid(this.gridTarget, this.gridOptions);
    }
  }

  toggleAccountCategories() {
    if (this.isOptionChecked("show_account_categories")) {
      this.gridApi.setGridOption('rowData', this.rowDataValue);
    } else {
      this.gridApi.setGridOption('rowData', this.rowDataValue.filter(row => !('postable' in row) || row.postable));
    }
  }

  toggleCompletedPeriods() {
    let columns = this.completedPeriodColumnsValue;

    if (this.isOptionChecked("show_completed_periods")) {
      if (!this.isOptionChecked("show_variance_amount")) {
        columns = columns.filter(completedColumn => !completedColumn.includes("variance_amount"));
      }

      if (!this.isOptionChecked("show_variance_percentage")) {
        columns = columns.filter(completedColumn => !completedColumn.includes("variance_percentage"));
      }
      
      this.gridApi.setColumnsVisible(columns, true);
    } else {
      this.gridApi.setColumnsVisible(columns, false);
    }

    this.gridApi.autoSizeAllColumns();
  }

  toggleVarianceAmount() {
    const showVarianceAmount = this.isOptionChecked("show_variance_amount");
    let columns = this.getChildColumnsByField("variance_amount");
    
    if (showVarianceAmount && columns.length > 0) {
      if (!this.isOptionChecked("show_completed_periods")) {
        columns = columns.filter(column => !this.completedPeriodColumnsValue.includes(column));
      }
      this.gridApi.setColumnsVisible(columns, true);
    } else if (!showVarianceAmount && columns.length > 0) {
      this.gridApi.setColumnsVisible(columns, false);
    }
    
    this.gridApi.autoSizeAllColumns();
  }

  toggleVariancePercentage() {
    const showVariancePercentage = this.isOptionChecked("show_variance_percentage");
    let columns = this.getChildColumnsByField("variance_percentage");

    if (showVariancePercentage && columns.length > 0) {
      if (!this.isOptionChecked("show_completed_periods")) {
        columns = columns.filter(column => !this.completedPeriodColumnsValue.includes(column));
      }
      this.gridApi.setColumnsVisible(columns, true);
    } else if (!showVariancePercentage && columns.length > 0) {
      this.gridApi.setColumnsVisible(columns, false);
    }

    this.gridApi.autoSizeAllColumns();
  }

  toggleRemainingBudget() {
    const remainingBudgetColumns = this.getChildColumnsByField("remaining")

    if (this.isOptionChecked("show_remaining_budget")) {
      this.gridApi.setColumnsVisible(remainingBudgetColumns, true);
    } else {
      this.gridApi.setColumnsVisible(remainingBudgetColumns, false);
    }

    this.gridApi.autoSizeAllColumns();
  }

  getChildColumnsByField(fieldName: string): string[] {
    return this.columnDefsValue
      .flatMap(columnDef => columnDef.children)
      .filter(column => column.field.includes(fieldName))
      .map(column => column.field);
  }

  isOptionChecked(name: string): boolean {
    return this.optionTargets.find((option) => option.name === name).checked;
  }
}
