import { Controller } from "@hotwired/stimulus";
import tippy, { Instance as TippyInstance } from "tippy.js";

export default class extends Controller {
  static targets = ["tooltipContent"];

  declare tippy: TippyInstance;
  declare tooltipContentTarget: HTMLTemplateElement;

  connect() {
    this.tippy = tippy(this.element, {
      content: this.tooltipContentTarget.innerHTML,
      placement: "bottom",
      allowHTML: true,
    });
  }

  disconnect() {
    this.tippy.destroy();
  }
}
