import {
  ICellRendererComp,
  ICellRendererParams,
} from "ag-grid-enterprise";

export class ActualCellRenderer implements ICellRendererComp {
  eGui!: HTMLDivElement;

  init(params: ICellRendererParams) {
    this.eGui = document.createElement("div");

    const field = params.colDef.field; // e.g. "Jan.actual"
    const period = field.split(".")[0]; // e.g. "Jan"
    const url_path = params.data[period].actual_url_path; // e.g. "/ledger_management/detailed_ledger_entries"
    this.eGui.innerHTML = `<a href="${url_path}" class="text-sumit-primary-black" target = "_blank">${params.value}</a>`;
  }

  getGui() {
    return this.eGui;
  }

  refresh(_params: ICellRendererParams) {
    return false;
  }
}
