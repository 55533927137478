import { Controller } from "@hotwired/stimulus";
import { get } from "@rails/request.js";
import TomSelect from "tom-select";
import { TomOption, TomInput } from "tom-select/dist/cjs/types";
import { loadingIcon } from "../helpers";

interface AccountCode {
  id: string;
  name: string;
  accountCodeGroupId: string;
  accountCodeGroupName: string;
  accountCodeGroupType: string;
}

// Connects to data-controller="account-code-select"
export default class extends Controller<HTMLSelectElement> {
  static values = {
    accountCodeUrl: String,
    classes: Array,
    itemClasses: Array,
    includeUncoded: {
      type: Boolean,
      default: false,
    },
    includeDropdownPlugin: {
      type: Boolean,
      default: false,
    },
    lock: Boolean,
    disabled: Boolean,
    id: String,
    useCustomTrigger: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: Array,
      default: ["remove_button"]
    }
  };

  static targets = ["selectElement","customTrigger"];

  declare select: TomSelect;
  declare hasClassesValue: boolean;
  declare classesValue: string[];
  declare hasItemClassesValue: boolean;
  declare itemClassesValue: string[];
  declare includeUncodedValue: boolean;
  declare includeDropdownPluginValue: boolean;
  declare lockValue: boolean;
  declare disabledValue: boolean;
  declare accountCodeUrlValue: string;
  declare hasAccountCodeUrlValue: boolean;
  declare idValue: string;
  declare hasIdValue: string;
  declare useCustomTriggerValue: boolean;
  declare pluginsValue: string[];
  declare selectElementTarget: HTMLSelectElement;
  declare customTriggerTarget: HTMLElement;
  declare templateElements: Array<HTMLTemplateElement>;

  private mousedownListener: (event: MouseEvent) => void;
  private mouseupListener: () => void;
  private elementMousedownListener: (event: MouseEvent) => void;

  connect() {
    if (this.lockValue) {
      this.pluginsValue = [];
    }

    this.templateElements = Array.from(this.element.querySelectorAll("template"));

    const options = {
      plugins: this.pluginsValue,
      preload: "focus",
      load: this.loadOptions.bind(this),
      score: this.scoreSearchResults,
      maxOptions: 100,
      hidePlaceholder: true,
      valueField: "id",
      labelField: "name",
      searchField: ["name"],
      optgroupField: "accountCodeGroupId",
      optgroupLabelField: "accountCodeGroupName",
      optgroupValueField: "accountCodeGroupId",
      onBlur: () => {
        // We want the options to reload when the user focuses on the select
        this.select.wrapper.classList.remove("preloaded");
        this.select.clearOptions();
        this.select.settings.load = this.loadOptions.bind(this);
      },
      render: {
        option: (item: AccountCode, escape: (value: string) => string) => {
          if (item.id === "uncoded") {
            return this.buildUncodedOption(item, escape);
          } else if (item.id.startsWith("not_coded_with_")) {
            return `<span class="hidden"></span>`; // Don't render, this is a ghost option
          } else {
            return this.buildAccountCodeOption(item, escape);
          }
        },
        item: (item: AccountCode, escape: (value: string) => string) => {
          if (item.id === "uncoded" || item.id.startsWith("not_coded_with_")) {
            return this.buildUncodedItem(item, escape);
          } else {
            return this.buildAccountCodeItem(item, escape);
          }
        },
        optgroup_header: (data: AccountCode, escape: (value: string) => string) => {
          if (data.accountCodeGroupId === "uncoded") {
            return "";
          }
          const optGroupHeader = this.tempEl("optgroupHeader");
          optGroupHeader.firstElementChild.textContent = this.decodeHtmlEntities(escape(data.accountCodeGroupName));
          optGroupHeader.append(this.buildNoGroupButton(data));
          optGroupHeader.append(this.buildNewButton(data));
          return optGroupHeader;
        },
        loading: (_data, _escape) => {
          return `
            <div class="p-1 flex flex-row gap-2 items-center">
              ${loadingIcon()}
              <span>Loading...</span>
            </div>
          `;
        },
      },
      onItemAdd: (values: string[]) => {
        this.select.setTextboxValue("");
        this.toggleUncodedSelection(values);
      },
      onInitialize: () => {
        if (this.allowAddNewButton()) {
          const dropdown = this.selectElementTarget.nextElementSibling.querySelector(".ts-dropdown");

          dropdown.appendChild(this.tempEl("fullNewButton"));
        }
      },
    };

    this.select = new TomSelect(this.selectElementTarget as TomInput, options);

    if (this.hasClassesValue) {
      this.classesValue.forEach((className) => {
        this.select.control.classList.add(className);
      });
    }

    if (this.lockValue) {
      this.select.lock();
    }

    if (this.disabledValue) {
      this.disableSelect();
    }

    if (this.useCustomTriggerValue) {
      this.setupCustomTrigger();
      if (this.element.parentElement) {
        this.element.parentElement.classList.remove("hidden");
      }
    }
  }

  tempEl(templateName) {
    const template = this.templateElements.find((element) => {
      if (element.dataset.templateName === templateName) {
        return element;
      }
    });

    return template.content.firstElementChild.cloneNode(true) as HTMLElement;
  }

  allowAddNewButton() {
    const whitelist = [
      new RegExp("/ledger_management/journal_entries/new"),
      new RegExp("/ledger_management/ledger_transactions/lt_(.*)/duplicate$"),
      new RegExp("/ledger_management/ledger_transactions/lt_(.*)/reverse$"),
      new RegExp("/account_reconciliation/connected_transactions"),
      new RegExp("/payables_management(.*)"),
    ];

    for (const regex of whitelist) {
      if (regex.test(window.location.href)) {
        return true;
      }
    }

    return false;
  }

  buildNoGroupButton(data) {
    if (!this.includeUncodedValue) {
      return "";
    }

    const iconNoAccountCodeGroupButton = this.tempEl("iconNoAccountCodeGroupButton");
    const innerButton = iconNoAccountCodeGroupButton.firstElementChild as HTMLElement;

    innerButton.setAttribute("data-action", "click->account-code-select#addNoGroupAccountCode");
    innerButton.setAttribute("data-account-code-group-id", data.accountCodeGroupId);

    return iconNoAccountCodeGroupButton;
  }

  addNoGroupAccountCode(event) {
    const accountCodeGroupId = event.currentTarget.dataset.accountCodeGroupId;
    this.select.addItem(`not_coded_with_${accountCodeGroupId}`);

    // remove any other account codes from the account code group
    for (const value in this.select.options) {
      const accountCode = this.select.options[value];
      const codeInAccountCodeGroup =
        accountCode.accountCodeGroupId === accountCodeGroupId &&
        accountCode.id !== `not_coded_with_${accountCodeGroupId}`;
      if (codeInAccountCodeGroup || accountCode.id === "uncoded") {
        this.select.removeItem(accountCode.id);
      }
    }
  }

  buildNewButton(data) {
    if (!this.allowAddNewButton() || data.accountCodeGroupType === "system") {
      return "";
    }

    const iconNewButton = this.tempEl("iconNewButton");
    const innerButton = iconNewButton.firstElementChild as HTMLElement;
    const card = JSON.parse(innerButton.dataset.card);
    const cardTurboSrcUrl = new URL(card.turboSrc, window.location.origin);
    cardTurboSrcUrl.searchParams.append("account_code_group_id", data.accountCodeGroupId);

    card.turboSrc = cardTurboSrcUrl.pathname + cardTurboSrcUrl.search;
    card.turboId = `account_code_form_with_group_${data.accountCodeGroupId}_account_code`;

    innerButton.dataset.card = JSON.stringify(card);

    return iconNewButton;
  }

  handleIdEnabledEvent(event) {
    const isEnabled = event.detail.enabled;
    const id = event.detail.id;

    if (this.hasIdValue && this.idValue === id) {
      if (isEnabled) {
        this.enableSelect();
      } else {
        this.disableSelect();
      }
    }
  }

  handleToggleEvent(event) {
    const isChecked = event.detail.checked;

    if (isChecked) {
      this.disableSelect();
    } else {
      this.enableSelect();
    }
  }

  enableSelect() {
    this.select.enable();
  }

  disableSelect() {
    this.select.disable();
    this.select.clear();
  }

  unfocusSelect() {
    this.select.blur();
  }

  disconnect() {
    this.cleanupEventListeners();
    this.select.destroy();
  }

  toggleUncodedSelection(values: string[]) {
    const isUncodedSelected = values.includes("uncoded");

    if (isUncodedSelected) {
      for (const value in this.select.options) {
        const accountCode = this.select.options[value];
        if (accountCode.id !== "uncoded") {
          this.select.removeItem(accountCode.id);
        }
      }
    } else {
      this.select.removeOption("uncoded");
    }

    // get all account code group ids from the selected account codes
    let notCodedWithAccountCodeGroupIds: string[] = [];
    if (Array.isArray(values)) {
      const notCodedWithAccountCodeGroupIds = [];
      values.map((id) => {
        if (!id.startsWith("not_coded_with_")) {
          notCodedWithAccountCodeGroupIds.push(`not_coded_with_${this.select.options[id].accountCodeGroupId}`);
        }
      });
    } else {
      if (!(values as string).startsWith("not_coded_with_")) {
        notCodedWithAccountCodeGroupIds = [`not_coded_with_${this.select.options[values].accountCodeGroupId}`];
      }
    }

    // we want to remove any pre-existing not_coded_with options if we select an account code
    // with an account group id that matches the not_coded_with option
    for (const value in this.select.options) {
      const accountCode = this.select.options[value];
      if (notCodedWithAccountCodeGroupIds.includes(accountCode.id)) {
        this.select.removeItem(accountCode.id);
      }
    }
  }

  // Build out the optgroup data structure for TomSelect
  buildOptGroups(accountCodes: AccountCode[]): TomOption[] {
    const accountCodeGroups = accountCodes.reduce((groups, accountCode) => {
      if (!groups[accountCode.accountCodeGroupId]) {
        groups[accountCode.accountCodeGroupId] = {
          accountCodeGroupName: accountCode.accountCodeGroupName,
          accountCodeGroupId: accountCode.accountCodeGroupId,
          accountCodeGroupType: accountCode.accountCodeGroupType,
        };
      }
      return groups;
    }, {});

    if (this.includeUncodedValue) {
      accountCodeGroups[0] = this.uncodedAccountCodeGroupDefinition();
    }

    return Object.values(accountCodeGroups);
  }

  loadOptions(
    _query: string,
    callback: (accountCodes: AccountCode[] | void, optionGroups: TomOption[] | void) => void,
  ) {
    if (this.select.loading > 1) {
      callback();
      return;
    }

    get(this.accountCodeUrlValue, { responseKind: "json" })
      .then((response: Response) => response.json)
      .then((payload: AccountCode[]) => {
        const optionGroups = this.buildOptGroups(payload);

        if (this.includeUncodedValue) {
          // Add "Uncoded" to the beginning of the list
          payload.unshift(this.uncodedAccountCodeDefinition());

          // for each optionGroup, build an option for not having that account code group
          for (const optionGroup of optionGroups) {
            payload.push(this.notCodedWithAccountCodeGroupDefinition(optionGroup));
          }
        }

        callback(payload, optionGroups);

        // We only want to load the data once, so we set the load function to null
        this.select.settings.load = null;
      })
      .catch((error) => {
        console.error(error);

        callback();
      });
  }

  scoreSearchResults(search) {
    return (item) => {
      let score = 0;

      const lowerCaseName = item.name.toLowerCase();
      const lowerCaseAccountCodeGroupName = item.accountCodeGroupName.toLowerCase();
      const lowerCaseSearch = search.toLowerCase();

      if (lowerCaseName === lowerCaseSearch) {
        score = 1;
      } else if (lowerCaseName.includes(lowerCaseSearch)) {
        score = 0.75;
      } else if (lowerCaseAccountCodeGroupName === lowerCaseSearch) {
        score = 0.5;
      } else if (lowerCaseAccountCodeGroupName.includes(lowerCaseSearch)) {
        score = 0.25;
      }

      return score;
    };
  }

  buildUncodedOption(item, escape: (value: string) => string) {
    const style = `style="background-color: #ffffff; color: #000000;"`;
    return `
      <div class="tag-selector inline-flex items-center m-1 px-2 py-1 text-xs font-medium border rounded-xl" ${style}>
        <span>
          ${escape(item.name)}
        </span>
      </div>
    `;
  }

  buildAccountCodeOption(item, escape: (value: string) => string) {
    const style = `style="background-color: #E1EFFE; color: #6A6A6A;"`;
    return `
      <div class="tag-selector inline-flex items-center m-1 px-2 py-1 text-xs font-semibold border border-transparent rounded-xl" ${style}>
        <span>
          ${escape(item.name)}
        </span>
      </div>
    `;
  }

  buildUncodedItem(item, escape: (value: string) => string) {
    const style = `style="background-color: #ffffff; color: #000000;"`;
    return `
      <div class="flex p-1 mr-2 font-medium text-xs rounded-xl w-fit !border !border-gray-300" ${style}>
        ${escape(item.name)}
      </div>
    `;
  }

  buildAccountCodeItem(item, escape: (value: string) => string) {
    const style = `style="background-color: #E1EFFE; color: #6A6A6A;"`;
    let itemClasses = "";
    if (this.hasItemClassesValue) {
      itemClasses = this.itemClassesValue.join(" ");
    }
    return `
      <div class="flex p-1 border mr-2 font-semibold rounded-xl w-fit ${itemClasses}" ${style}>
        ${escape(item.accountCodeGroupName + ": " + item.name)}
      </div>
    `;
  }

  uncodedAccountCodeGroupDefinition() {
    return {
      accountCodeGroupName: "Uncoded",
      accountCodeGroupId: "uncoded",
      accountCodeGroupType: "system",
    };
  }

  uncodedAccountCodeDefinition() {
    return {
      id: "uncoded",
      name: "Uncoded",
      colorBg: "#ffffff",
      colorFg: "#000000",
      accountCodeGroupId: "uncoded",
      accountCodeGroupName: "Uncoded",
      accountCodeGroupType: "system",
    };
  }

  notCodedWithAccountCodeGroupDefinition(optionGroup) {
    return {
      id: `not_coded_with_${optionGroup.accountCodeGroupId}`,
      name: `Not Coded w/ ${optionGroup.accountCodeGroupName}`,
      colorBg: "#ffffff",
      colorFg: "#000000",
      accountCodeGroupId: optionGroup.accountCodeGroupId,
      accountCodeGroupName: optionGroup.accountCodeGroupName,
      accountCodeGroupType: optionGroup.accountCodeGroupType,
    };
  }

  // creates a temporary <div>, assigns the encoded string to its innerHTML, and retrieves
  // the decoded string from textContent to display special characters properly
  decodeHtmlEntities(encodedString) {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = encodedString;
    return tempDiv.textContent || tempDiv.innerText || "";
  }

  private setupCustomTrigger() {
    this.hideDefaultControl();
    this.setupCustomTriggerEvents();
  }

  private hideDefaultControl() {
    const control = this.select.wrapper.querySelector('.ts-control') as HTMLElement;
    if (control) {
      control.style.display = 'none';
    }
  }

  private setupCustomTriggerEvents() {
    let isClosing = false;

    // Store the listeners so we can remove them later
    this.mousedownListener = (event: MouseEvent) => {
      event.preventDefault();
      event.stopPropagation();

      if (this.select.isOpen) {
        isClosing = true;
        this.select.close();
      } else {
        this.select.open();
      }
    };

    this.mouseupListener = () => {
      setTimeout(() => { isClosing = false; }, 100);
    };

    this.elementMousedownListener = (event: MouseEvent) => {
      if (isClosing) {
        event.stopPropagation();
      }
    };

    this.customTriggerTarget.addEventListener('mousedown', this.mousedownListener);
    this.customTriggerTarget.addEventListener('mouseup', this.mouseupListener);
    this.element.addEventListener('mousedown', this.elementMousedownListener);
  }

  private cleanupEventListeners() {
    if (this.mousedownListener) {
      this.customTriggerTarget.removeEventListener('mousedown', this.mousedownListener);
    }
    if (this.mouseupListener) {
      this.customTriggerTarget.removeEventListener('mouseup', this.mouseupListener);
    }
    if (this.elementMousedownListener) {
      this.element.removeEventListener('mousedown', this.elementMousedownListener);
    }
  }

  reconnect() {
    this.disconnect();
    this.connect();
  }
}
