import * as math from "mathjs";

interface CurrencyFormatterOptions {
  throwOnInvalid?: boolean;
}

export function formatCurrencyValue(inputValue: string, options: CurrencyFormatterOptions = {}): string {
  // Remove all non-numerical characters except for math operators (+, -, *, /) and decimal point
  const numericValue = inputValue.replace(/,|^0+|[^0-9.+-/*]/g, "");

  let result = "";
  try {
    result = math.evaluate(numericValue);
  } catch {
    if (options.throwOnInvalid) {
      throw new Error("Invalid numeric expression");
    }
  }

  let numberValue = parseFloat(result);
  if (isNaN(numberValue) || numberValue === Infinity) {
    if (options.throwOnInvalid) {
      throw new Error("Invalid number");
    }
    numberValue = 0;
  }

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: "USD",
    minimumFractionDigits: 2,
  }).format(numberValue);
}
