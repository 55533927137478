import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="cell-nav"
export default class extends Controller {
  static targets = ["cell"];
  declare cellTargets: HTMLElement[];

  static values = {
    colCount: Number,
    groupsCount: Number,
  };
  declare colCountValue: number;
  declare groupsCountValue: number;

  handleEscapeOrEnterKey(event) {
    event.preventDefault();

    const inputElement = event.currentTarget as HTMLInputElement;
    inputElement.blur();
  }

  handleTab(event) {
    event.preventDefault();

    const currentCell = event.target as HTMLElement;
    const currentColIndex = parseInt(currentCell.dataset.colIndex, 10);
    const currentRowIndex = parseInt(currentCell.dataset.rowIndex, 10);
    const currentGroupIndex = parseInt(currentCell.dataset.groupIndex, 10);
    const currentGroupRowsCount = parseInt(currentCell.dataset.groupRowsCount, 10);

    let nextColIndex = currentColIndex + 1;
    let nextRowIndex = currentRowIndex;
    let nextGroupIndex = currentGroupIndex;

    // If we exceed the number of columns, loop back to the first column
    if (nextColIndex > this.colCountValue) {
      nextColIndex = 1; // Reset to the first column
      nextRowIndex = currentRowIndex + 1; // go to next row

      if (nextRowIndex >= currentGroupRowsCount) {
        nextRowIndex = 0; // Reset to the first line
        nextColIndex = 1; // Reset to the first column
        nextGroupIndex = currentGroupIndex + 1; // go to next group

        if (nextGroupIndex >= this.groupsCountValue) {
          nextGroupIndex = 0; // Reset to the first group
        }

        const cellFromNextGroup = this.findCell(nextColIndex, 0, nextGroupIndex);
        const isCellVisible = cellFromNextGroup ? cellFromNextGroup.offsetParent !== null : false;

        if (!isCellVisible) {
          nextGroupIndex = currentGroupIndex;
        }
      }
    }

    this.selectCell(nextColIndex, nextRowIndex, nextGroupIndex);
  }

  handleShiftTab(event) {
    event.preventDefault();

    const currentCell = event.target as HTMLElement;
    const currentColIndex = parseInt(currentCell.dataset.colIndex, 10);
    const currentRowIndex = parseInt(currentCell.dataset.rowIndex, 10);
    const currentGroupIndex = parseInt(currentCell.dataset.groupIndex, 10);

    let nextColIndex = currentColIndex - 1;
    let nextRowIndex = currentRowIndex;
    let nextGroupIndex = currentGroupIndex;

    // If we exceed the number of columns, loop back to the first column
    if (nextColIndex < 1) {
      nextColIndex = this.colCountValue; // Reset to the last column
      nextRowIndex = currentRowIndex - 1; // go to previous row

      if (nextRowIndex < 0) {
        nextGroupIndex = currentGroupIndex - 1; // go to previous group
        if (nextGroupIndex < 0) {
          nextGroupIndex = this.groupsCountValue - 1; // Reset to the last group
        }

        const cellFromPrevGroup = this.findCell(nextColIndex, 0, nextGroupIndex);
        const prevGroupRowsCount = cellFromPrevGroup ? parseInt(cellFromPrevGroup.dataset.groupRowsCount, 10) : 1;

        nextRowIndex = prevGroupRowsCount - 1;

        const isCellVisible = cellFromPrevGroup ? cellFromPrevGroup.offsetParent !== null : false;

        if (!isCellVisible) {
          nextGroupIndex = currentGroupIndex;
          nextRowIndex = parseInt(currentCell.dataset.groupRowsCount, 10) - 1;
        }
      }
    }

    this.selectCell(nextColIndex, nextRowIndex, nextGroupIndex);
  }

  handleDown(event) {
    event.preventDefault();

    const currentCell = event.target as HTMLElement;
    const currentColIndex = parseInt(currentCell.dataset.colIndex, 10);
    const currentRowIndex = parseInt(currentCell.dataset.rowIndex, 10);
    const currentGroupIndex = parseInt(currentCell.dataset.groupIndex, 10);
    const currentGroupRowsCount = parseInt(currentCell.dataset.groupRowsCount, 10);

    const nextColIndex = currentColIndex;
    let nextRowIndex = currentRowIndex + 1;
    let nextGroupIndex = currentGroupIndex;

    // If we exceed the number of lines, loop back to the first line
    if (nextRowIndex >= currentGroupRowsCount) {
      nextRowIndex = 0; // Reset to the first line
      nextGroupIndex = currentGroupIndex + 1; // go to next group

      if (nextGroupIndex >= this.groupsCountValue) {
        nextGroupIndex = 0; // Reset to the first group
      }

      const cellFromNextGroup = this.findCell(nextColIndex, 0, nextGroupIndex);
      const isCellVisible = cellFromNextGroup ? cellFromNextGroup.offsetParent !== null : false;

      if (!isCellVisible) {
        nextGroupIndex = currentGroupIndex;
      }
    }

    this.selectCell(nextColIndex, nextRowIndex, nextGroupIndex);
  }

  handleUp(event) {
    event.preventDefault();

    const currentCell = event.target as HTMLElement;
    const currentColIndex = parseInt(currentCell.dataset.colIndex, 10);
    const currentRowIndex = parseInt(currentCell.dataset.rowIndex, 10);
    const currentGroupIndex = parseInt(currentCell.dataset.groupIndex, 10);

    const nextColIndex = currentColIndex;
    let nextRowIndex = currentRowIndex - 1;
    let nextGroupIndex = currentGroupIndex;

    // If we exceed the number of rows, loop back to the first row
    if (nextRowIndex < 0) {
      nextGroupIndex = currentGroupIndex - 1; // go to prev group
      if (nextGroupIndex < 0) {
        nextGroupIndex = this.groupsCountValue - 1;
      }

      // select a cell from the next group to get the total lines for that group
      const cellFromNextGroup = this.findCell(nextColIndex, 0, nextGroupIndex);
      const isCellVisible = cellFromNextGroup ? cellFromNextGroup.offsetParent !== null : false;

      if (isCellVisible) {
        const nextGroupRowsCount = cellFromNextGroup ? parseInt(cellFromNextGroup.dataset.groupRowsCount, 10) : 1;
        nextRowIndex = nextGroupRowsCount - 1;
      } else {
        nextGroupIndex = currentGroupIndex;
        nextRowIndex = parseInt(currentCell.dataset.groupRowsCount, 10) - 1;
      }
    }

    this.selectCell(nextColIndex, nextRowIndex, nextGroupIndex);
  }

  findCell(nextColIndex: number, nextRowIndex: number, nextGroupIndex: number): HTMLElement | undefined {
    return this.cellTargets.find(cell =>
      parseInt(cell.dataset.colIndex, 10) === nextColIndex &&
      parseInt(cell.dataset.rowIndex, 10) === nextRowIndex &&
      parseInt(cell.dataset.groupIndex, 10) === nextGroupIndex
    );
  }

  selectCell(nextColIndex: number, nextRowIndex: number, nextGroupIndex: number): HTMLElement | undefined {
    const nextCell = this.findCell(nextColIndex, nextRowIndex, nextGroupIndex);

    if (nextCell) {
      // If this is the commentary column (last column)
      if (nextColIndex === this.colCountValue) {
        (nextCell as HTMLInputElement).focus();
      } else {
        // For number cells, click the parent to open the edit form
        nextCell.parentElement.click();
      }
    }

    return nextCell;
  }
} 
